import { useScanDestinationNavigator } from '../scan-destination/ScanDestination.navigator'
import { useNavigate } from 'react-router-dom'
import { type TransferOptionSelected } from '../../../components/molecules/MoveProductsSelector.molecule'
import {
  transferHasLooseProducts,
  transferItemsAtom,
  transferOptionSelectedAtom,
  transferOriginInfoReader
} from '../transfer.state'
import { useAtom } from 'jotai/index'
import { useEffect, useState } from 'react'
import { useMoveProductListNavigator } from '../move-product-list/MoveInventoryProductList.navigator'


export const useMoveOptions = () => {
  const navigate = useNavigate()

  const [origin] = useAtom(transferOriginInfoReader)
  const [, setOption] = useAtom(transferOptionSelectedAtom)
  const [hasLooseProducts] = useAtom(transferHasLooseProducts)
  const [products] = useAtom(transferItemsAtom)

  const [options, setOptions] = useState<TransferOptionSelected[]>([])

  const scanDestinationNavigator = useScanDestinationNavigator()
  const productListNavigator = useMoveProductListNavigator()


  /* Options active */
  useEffect(() => {
    const tmp: TransferOptionSelected[] = []
    if (products.length > 0) {
      tmp.push('move-products')
    }
    if (origin.container) {
      tmp.push('move-container')
    }
    if (hasLooseProducts) {
      tmp.push('move-loose-products')
    }
    setOptions(tmp)
  }, [products])

  return {
    origin,
    options,
    actions: {
      back: () => {
        navigate(-1)
      },
      onClickGo: (option: TransferOptionSelected) => () => {
        setOption(option)
        switch (option) {
          case 'move-container':
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            scanDestinationNavigator.go(origin)
            break
          case 'move-products':
          case 'move-loose-products':
            productListNavigator.go(origin)
            break
        }
      }
    }
  }
}
