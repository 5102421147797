import { InventoryProduct, ProductDemand, type ProductInfo } from '../../types/model'
import { productGatewayAxiosInstance } from '../../utils/op-product-gateway.axios.instance'
import { type ProductDetailsResponse } from './products.types'
import { ProductResponse } from 'client/inventory/inventory.types'
import { productDemandAxiosInstance } from 'utils/op-product-demand.axios.instance'

export const findProductInfo = async (ean: string): Promise<ProductInfo> => {
  return await productGatewayAxiosInstance().get<ProductInfo>(`/v1/products/${ean}`)
    .then((res) => res.data)
}

export const findProductsInfo = async (eans: string[]): Promise<ProductInfo[]> => {
  return await productGatewayAxiosInstance()
    .get<ProductInfo[]>('/v1/products/multiple/eans', { params: { n: eans.join(',') } })
    .then((res) => res.data)
}

export const getProductDemand = async (ean: string): Promise<number> => {
  return await productDemandAxiosInstance()
    .get<ProductDemand>(`/demands/${ean}/average`, { params: { from: '30d', tz: 'America/Mexico_City' } })
    .then((res) => Math.ceil(res.data.value))
}

export const mapProductsWithInfo = async (products: ProductResponse[]) => {
  const productsWithEan = await findProductsInfo(products.map(p => p.productEan))
  return products.map<InventoryProduct>((p) => {
    const product = productsWithEan.find(it => it.ean === p.productEan)
    return {
      productId: product?.id ?? 'NULL',
      productEan: product?.ean ?? p.productEan,
      productSku: product?.sku ?? '-',
      productName: product?.name ?? 'PRODUCTO DESCONOCIDO',
      productImage: product?.image ?? 'https://media.justo.mx/fallback.jpg',
      byWeight: product?.isWeighable ?? false,
      stock: p.stock,
      country: p.country,
      warehouse: p.warehouse
    }
  })
}
