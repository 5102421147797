import { useNavigate } from 'react-router-dom'

export const useGoToUniversalSearchLocation = () => {
  const navigate = useNavigate()

  return {
    go: () => {
      navigate(UNIVERSAL_SEARCH_LOCATION_URL)
    }
  }
}

export const UNIVERSAL_SEARCH_LOCATION_URL = '/universal-search/location'
