import {useEffect, useState} from "react";
import {onErrorToast} from "../../../onErrorToast.util";
import { ProductWithInfo } from "../universal-search.types";
import { useAtom } from "jotai";
import { universalSearchContainerAtomReader, universalSearchLocationAtomReader } from "../universal-search.state";
import { useNavigate } from "react-router-dom";
import { getProducts } from "client/inventory/inventory.client";
import { findProductsInfo } from "client/products/products.client";
import { UomKey } from "client/types";
import { getActiveProductsFromSuggestion } from "client/alerts/alerts.client";
import { AlertSuggestionProduct } from "types/model";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

export function useUniversalSearchContainer() {
  const [container] = useAtom(universalSearchContainerAtomReader)
  const [location] = useAtom(universalSearchLocationAtomReader)
  const [alerts, setAlerts] = useState<AlertSuggestionProduct[]>([])
  const [products, setProducts] = useState<ProductWithInfo[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const isProductDemandOn = useFeatureIsOn("op_product_demand");

  useEffect(() => {
    setLoading(true)
    Promise.all([
      getActiveProductsFromSuggestion(container.name, isProductDemandOn),
      getProducts(container.name)
    ])
    .then(async ([alerts, products]) => {
      const tempProducts = products.length > 0 ? await findProductsInfo(products.map((it) => it.productEan)) : []
      const producstWithExtraInfo = products.map((it) => {
        const product = tempProducts.find((p) => p.ean === it.productEan)
        let productWithExtraInfo: ProductWithInfo = {...it, productName: '', productImage: '', isWeighable: false }
        if (product != null) {
          productWithExtraInfo.productName = product.name as string
          productWithExtraInfo.productImage = product.image as string
          productWithExtraInfo.isWeighable = product.packagingLevels.some((pl) => pl.uom === UomKey.Weight)
        }
        return productWithExtraInfo
      })
      setAlerts(alerts)
      setProducts(producstWithExtraInfo)
    })
    .catch(e => {
      onErrorToast("Error al obtener productos en contenedor: " + e.message)
    })
    .finally(() => {
      setLoading(false)
    })
  }, [])
  
  return { 
    container,
    location,
    products,
    alerts,
    loading,
    goBack: () => navigate(-1)
  }
}
