import { useEffect, useState } from "react";
import { type ProductWithInfo } from "../universal-search.types";
import { onErrorToast } from "../../../onErrorToast.util";
import { useAtom } from "jotai";
import { universalSearchScanAtomReader } from "../universal-search.state";
import { useNavigate } from "react-router-dom";
import { findProductsInfo } from "client/products/products.client";
import { UomKey } from "client/types";
import { getProducts } from "client/inventory/inventory.client";
import { LocationResponse } from "client/scanner/scanner.types";
import { getActiveProductsFromSuggestion } from "client/alerts/alerts.client";

export type AlertCounts = {
  urgent: number;
  critical: number;
  warning: number;
};

type GroupedContainer = {
  products: ProductWithInfo[];
  alerts: AlertCounts;
};

type GroupedProducts = Record<string, GroupedContainer>;

export const useUniversalSearchLocation = () => {
  const [scan] = useAtom(universalSearchScanAtomReader);
  const [containers, setContainers] = useState<GroupedProducts>({});
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const location = scan.location as LocationResponse;

  useEffect(() => {
    if (!location) return;
    setLoading(true);

    getProducts(location.name)
      .then(async (products) => {
        if (products.length === 0) return {};

        const tempProducts = await findProductsInfo(products.map((it) => it.productEan));

        const groupedProducts = products.reduce<Record<string, ProductWithInfo[]>>((acc, it) => {
          const product = tempProducts.find((p) => p.ean === it.productEan);
          const productWithExtraInfo: ProductWithInfo = {
            ...it,
            productName: product?.name || "",
            productImage: product?.image || "",
            isWeighable: product?.packagingLevels.some((pl) => pl.uom === UomKey.Weight) || false,
          };
          
          // Group products by container
          const containerKey = it.container || "unknown";
          if (!acc[containerKey]) {
            acc[containerKey] = [];
          }
          acc[containerKey].push(productWithExtraInfo);
          return acc;
        }, {});

        // Fetch alerts for each container
        const containers = await Promise.all(
          Object.entries(groupedProducts).map(async ([container, products]) => {
            try {
              const productsWithAlerts = await getActiveProductsFromSuggestion(container);

              const alertCounts = productsWithAlerts.reduce<AlertCounts>(
                (acc, item) => {
                  if (item.level === "Urgent") acc.urgent += 1;
                  if (item.level === "Critical") acc.critical += 1;
                  if (item.level === "Warning") acc.warning += 1;
                  return acc;
                },
                { urgent: 0, critical: 0, warning: 0 }
              );

              return [container, { products, alerts: alertCounts }];
            } catch (error) {
              console.error(`Error fetching suggestions for container ${container}:`, error);
              return [container, { products, alerts: { urgent: 0, critical: 0, warning: 0 } }];
            }
          })
        );

        return Object.fromEntries(containers);
      })
      .then(setContainers)
      .catch((e) => {
        onErrorToast("Error al obtener productos de inventario en ubicación: " + location.name + ": " + e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return {
    location,
    containers,
    loading,
    goBack: () => navigate(-1),
  };
};
