import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { useRequiredParams } from 'hooks/params.hook'
import { extractWeight } from 'utils/product'
import { getLocationOrContainerFromScan } from 'client/scanner/scanner.client'
import {
  ActiveRestockInventoryCountAtom,
  AlertsSelectedAtom,
  ProductSelectedAtom
} from '../Alerts.state'
import { useConfirmationDialog } from 'components/molecules/ConfirmationDialog.molecule'
import { restockCount } from 'client/alerts/alerts.client'
import { useNavigate } from 'react-router-dom'
import { waiting } from '../../../../utils/waiting'
import { useCountSystem } from '../../../../hooks/counts.hook'
import { ScannedContainer, ScannedLocation } from '../../../../client/scanner/scanner.types'
import { Analytics } from 'analytics/analytics.events'
import { useAlertsScanProductNavigator } from '../scan-product/ScanProduct.navigator'

export const useRegisterInventoryCount = () => {
  const { t } = useTranslation('global')
  const { ean } = useRequiredParams('ean')
  const { source } = useRequiredParams('source')
  const { destination } = useRequiredParams('destination')
  const navigate = useNavigate()
  const initialWeight = extractWeight(ean)
  const countSystem = useCountSystem(initialWeight)
  const alertsScanProductNavigator = useAlertsScanProductNavigator()

  const deleteWeightDialog = useConfirmationDialog<number>()

  const [alert] = useAtom(AlertsSelectedAtom)
  const [product] = useAtom(ProductSelectedAtom)
  const [activeRestockInventoryCount, setActiveRestockInventoryCount] = useAtom(
    ActiveRestockInventoryCountAtom
  )

  const startedAt = activeRestockInventoryCount?.startedAt ?? new Date().toISOString()

  const [loading, setLoading] = useState(false)
  const [origin, setOrigin] = useState<ScannedLocation | ScannedContainer | null>(null)

  /* On init: find source info for navigation to scan product page  */
  useEffect(() => {
    getLocationOrContainerFromScan(source)
      .then(setOrigin)
      .catch((e) => {
        console.error(e)
        toast.error('Error getting source location: ' + e.message)
      })
  }, [])

  const registerInventoryCount = () => {
    setLoading(true)
    restockCount({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ean: product!.ean,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      location: destination,
      quantity: countSystem.total,
      startedAt: startedAt
    })
      .then(async (res) => {
        toast.success(
          t('alerts.register-inventory-count.success', {
            quantity: countSystem.total,
            uom: product?.isWeighable ? 'g' : 'uds',
            product: product?.name ?? 'NN',
            location: destination
          })
        )
        Analytics.inventoryCount({
          count: countSystem.total,
          ean: product?.ean as string,
          difference: res.difference,
          quantity: res.quantity,
          type: res.type,
          location: destination,
          alertLevel: alert?.level || 'none',
          startedAt,
          duration: res.durationInSeconds
        })
        await waiting(3_000)
        setActiveRestockInventoryCount(undefined)
        alertsScanProductNavigator.go(origin!)
      })
      .catch((err: Error) => {
        toast.error('Error al registrar restock: ' + err.message)
        console.error(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    isLoading: loading,
    destination,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    product,
    counts: countSystem.counts,
    totalCounts: countSystem.total,
    deleteWeightDialogRef: deleteWeightDialog.dialogRef,
    weightToDelete: deleteWeightDialog.value ?? 0,
    actions: {
      goBack: () => {
        navigate(-1)
      },
      addItem: (item: number) => {
        countSystem.add(item)
      },
      onRemoveItem: (item: number) => {
        deleteWeightDialog.open(item)
      },
      confirmRemoveItem: () => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        countSystem.remove(deleteWeightDialog.value!)
      },
      registerInventoryCount: () => {
        if (countSystem.total <= 0) {
          toast.error(t('alerts.register-inventory-count.count-error'))
          return
        }
        registerInventoryCount()
      }
    }
  }
}
