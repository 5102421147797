import {useUniversalSearch} from "./universal-search.hooks";
import {
  AtlasBodyTitle,
  AtlasH1,
  Filler,
  Icons,
  PageContentAtom,
} from "@justomx/atlas-components";
import {useTranslation} from "react-i18next";
import LoaderAtom from "../../components/atoms/Loader.atom";
import { MenuWrapperOrganism } from "components/organisms/MenuWrapper.organism";
import { TextfieldDialogOrganism } from "components/organisms/TextfieldDialog.organism";

export const UniversalSearchPage = () => {
  const {loading, handleScanOrSearch, keyboardSearchDialogRef, openKeyboardSearchDialog} = useUniversalSearch()
  const {t} = useTranslation('global')

  return <>
    {loading && <LoaderAtom>{t('loader.message')}</LoaderAtom>}
    <MenuWrapperOrganism 
      title={t('universal-search.toolbar-title')}
      secondaryAction={Icons.IcKeyboardIcon}
      onSecondaryAction={openKeyboardSearchDialog}
    />
    <PageContentAtom $centered>
      <Filler flexGrow={1}/>
      <AtlasBodyTitle>{t('universal-search.scan')}</AtlasBodyTitle>
      <AtlasH1 style={{textAlign: 'center'}}>
        {t('universal-search.scan-anything')
        .split('\n')
        .map((line, index) => (
          <span key={index} style={{ display: 'block', marginBottom: '20px' }}>
            {line}
          </span>
        ))}
      </AtlasH1>
      <Filler/>
      <TextfieldDialogOrganism
        dialogRef={keyboardSearchDialogRef}
        title="Buscar"
        label="EAN, LPN o ubicación"
        confirmText="Continuar"
        cancelText="Cancelar"
        confirmAction={handleScanOrSearch}
      />
    </PageContentAtom>
  </>
}
