import { useScanner } from '../../../hooks/scanner.hook'
import { useEffect } from 'react'
import { manageError } from '../../../utils/errors/error.handler'
import { validate } from './ScanOrigin.validator'
import { useAtom } from 'jotai'
import { inventoryWasteOriginInfoAtom, inventoryWasteProductsAtom } from '../inventory-waste.state'
import { useInventoryWasteScanProduct } from '../scan-product/ScanProduct.navigator'
import { getScannedInfo } from '../../../client/scanner/scanner.client'
import { type ScannedResponse } from '../../../client/scanner/scanner.types'
import { getProducts } from 'client/inventory/inventory.client'

export const useScanOrigin = () => {
  const {
    keys,
    getScan
  } = useScanner()
  const scanProduct = useInventoryWasteScanProduct()
  const [, setLocationState] = useAtom(inventoryWasteOriginInfoAtom)
  const [, setInventoryProducts] = useAtom(inventoryWasteProductsAtom)

  useEffect(() => {
    const scanned = getScan()
    if (scanned) {
      getScannedInfo(scanned)
        .then((origin) => validate(origin as ScannedResponse))
        .then(async (origin) => {
          await getProducts(scanned).then((inventoryProducts) => {
            setInventoryProducts(inventoryProducts)
            setLocationState(origin)
            scanProduct.go()
          })
        })
        .catch(manageError(`ScanOrigin.hooks.ts#useEffect ${scanned}`))
    }
  }, [keys])

}
