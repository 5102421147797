import { toast } from 'react-toastify'
import { useTransferScanOriginNavigator } from '../scan-origin/ScanOrigin.navigator'
import { useNavigate } from 'react-router-dom'
import { type InventoryProduct } from '../../../types/model'
import { useEffect, useState } from 'react'
import {
  transferItemsReader,
  transferLooseProductsReader,
  transferOptionSelectedReader,
  transferOriginInfoReader,
  transferSelectedItemsAtom
} from '../transfer.state'
import { useAtom, useSetAtom } from 'jotai/index'
import { useMoveOneNavigator } from '../move-one-configuration/MoveOneConfiguration.navigator'
import { useScanDestinationNavigator } from '../scan-destination/ScanDestination.navigator'
import { localeIncludes } from '../../../utils/string'
import { mapProductsWithInfo } from 'client/products/products.client'

export const useMoveInventoryProductList = () => {
  const navigate = useNavigate()
  const scanOriginNavigator = useTransferScanOriginNavigator()
  const scanDestinationNavigator = useScanDestinationNavigator()
  const moveOneNavigator = useMoveOneNavigator()

  const [origin] = useAtom(transferOriginInfoReader)
  const setSelectedInventoryProducts = useSetAtom(transferSelectedItemsAtom)
  const [looseProducts] = useAtom(transferLooseProductsReader)
  const [products] = useAtom(transferItemsReader)
  const [option] = useAtom(transferOptionSelectedReader)

  const [selectedItems, setSelectedItems] = useState<InventoryProduct[]>([])
  const [search, setSearch] = useState<string>('')
  const [productsWithInfo, setProductsWithInfo] = useState<InventoryProduct[]>([])
  const [items, setItems] = useState<InventoryProduct[]>([])

  const getItems = async () => {
    const productsWithInfo = await mapProductsWithInfo(option === 'move-loose-products' ? looseProducts : products)
    setProductsWithInfo(productsWithInfo)
    setItems(productsWithInfo)
    return productsWithInfo
  }

  /* Assign items in the product list page */
  useEffect(() => {
    getItems()
  }, [])

  /* Set selected items in Atom */
  useEffect(() => {
    setSelectedInventoryProducts(selectedItems)
  }, [selectedItems])

  /* Filter items */
  useEffect(() => {
    setItems(productsWithInfo.filter(it => {
      const normalizedSearch = localeIncludes(search)
      if (normalizedSearch.length === 0) {
        return true
      }
      return normalizedSearch(it.productSku) ||
        normalizedSearch(it.productEan) ||
        normalizedSearch(it.productName)
    }))
  }, [search])

  return {
    origin,
    items,
    setSearch,
    hasSelectedItems: () => selectedItems.length > 0,
    selectedItems,
    actions: {
      moveProducts: () => {
        setSelectedInventoryProducts(selectedItems)
        if (option === 'move-loose-products') {
          scanDestinationNavigator.go(origin)
        } else if (selectedItems.length > 1) {
          scanDestinationNavigator.go(origin)
        } else {
          moveOneNavigator.go({
            origin,
            item: selectedItems[0]
          })
        }
      },
      cancel: () => {
        toast.success('Movimiento cancelado con éxito')
        scanOriginNavigator.go()
      },
      back: () => {
        navigate(-1)
      },
      toggleInventoryProduct: (inventoryProduct: InventoryProduct) => {
        if (selectedItems.find(e => e.productSku === inventoryProduct.productSku)) {
          setSelectedItems(prev => prev.filter(x => x.productSku !== inventoryProduct.productSku))
        } else {
          setSelectedItems(prev => prev.concat(inventoryProduct))
        }
      },
      selectAll: () => {
        setSelectedItems(productsWithInfo)
      },
      clearAll: () => {
        setSelectedItems([])
      }
    }
  }
}
