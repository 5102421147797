import { AtlasBodyMain, AtlasBodySubtitle, AtlasColor, CardComponent } from '@justomx/atlas-components'
import { useTranslation } from 'react-i18next'

interface Props {
  locations: string[]
  title: string
}

export function LocationAssignmentsMolecule ({ locations, title }: Props) {
  const { t } = useTranslation('global')
  return (
    <CardComponent style={{
      backgroundColor: '#424242',
      textAlign: 'center',
      gap: '12px'
    }}>
      <AtlasBodyMain color={AtlasColor.Neutral40}>{title}</AtlasBodyMain>
      {
        locations.length > 0
          ? locations.map((location, index) => {
            return (<AtlasBodySubtitle key={`${index}-${location}`}>{location}</AtlasBodySubtitle>)
          })
          : <AtlasBodySubtitle>No existen ubicaciones para reabastecer</AtlasBodySubtitle>
      }
    </CardComponent>
  )
}
