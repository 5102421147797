import {useTranslation} from "react-i18next";
import {AtlasCaption, PageContentAtom, ToolbarMolecule, Icons, AtlasBodySubtitle, AtlasH3, Divider, CardComponent, StackAtom, AtlasColor, AtlasBodyMain, AtlasListAtom} from "@justomx/atlas-components";
import LoaderAtom from "../../../components/atoms/Loader.atom";
import { LocationAndContainer } from "../components/location-and-container.component";
import { useUniversalSearchProduct } from "./universal-search-product.hook";
import ProductInfo from "../components/product-info.component";
import _ from "lodash";
import { ProductWithLocationInfo } from "../universal-search.types";
import {ChipMolecule} from "@justomx/atlas-components"
import { useState } from "react";
import EmptyListComponent from "components/molecules/EmptyList.molecule";

interface LocationSectionProps {
  title: string;
  items: ProductWithLocationInfo[];
  showStock?: boolean;
  emptyMessage: string;
}

const LocationSection: React.FC<LocationSectionProps> = ({ title, items, showStock=true, emptyMessage }) => {
  return (
    <>
      <AtlasH3>{title}</AtlasH3>
      {items.length > 0 ? (
        items.map((item, i) => (
          <>
          <LocationAndContainer
            item={item}
            showStock={showStock}
            key={i + '-' + item.location + '-' + item.container}
            isAssigned={item.isAssigned}
            i={i}
          />
          <Divider/>
          </>
        ))
      ) : (
        <EmptyListComponent text={emptyMessage}/>
      )}
    </>
  );
};


export const UniversalSearchProductPage = () => {
  const {product, productsByLocations, assignedLocations, loading, goBack} = useUniversalSearchProduct()
  const {t} = useTranslation('global')

  const [selectedLocation, setSelectedLocation] = useState<string>('Picking')

  const locationTypesMap = {
    Picking: t('universal-search.picking'),
    PickingFloor: t('universal-search.pickingfloor'),
    Storage: t('universal-search.storage'),
    PreStorage: t('universal-search.prestorage'),
    Preparation: t('universal-search.preparation'),
    Sampling: t('universal-search.sampling'),
  }

  return <>
    {loading && <LoaderAtom>{t('loader.message')}</LoaderAtom>}
    <ToolbarMolecule 
      title={t('universal-search.product.toolbar-title')}
      actionIcon={Icons.ArrowBack}
      onAction={goBack}
    />
    <PageContentAtom>
    <AtlasBodySubtitle>{t('universal-search.product.scanned-product')}</AtlasBodySubtitle>
    <CardComponent style={{ backgroundColor: AtlasColor.BackgroundInformative2}}>
      <ProductInfo product={product}/>
    </CardComponent>
    <AtlasBodySubtitle>{t("universal-search.instructions")}</AtlasBodySubtitle>
    <AtlasListAtom>
      <li><AtlasBodyMain display='contents'>{t('universal-search.select-type')}</AtlasBodyMain></li>
    </AtlasListAtom>
    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '8px', margin: '16px 0px'}}>
      {Object.entries(locationTypesMap).map(([key, value]) => (
          <ChipMolecule
            type="outlined"
            text={_.capitalize(value)}
            key={key}
            selected={selectedLocation === key}
            onClick={() => setSelectedLocation(key)}
            textColor="#7A7A7A"
            borderColor="#7A7A7A"
          />
        ))}
    </div>
    <LocationSection
      title={_.capitalize(t(`universal-search.${_.toLower(selectedLocation)}`))}
      items={
        selectedLocation === 'Picking'
          ? [
              ...(productsByLocations?.[selectedLocation] || []),
              ...(assignedLocations || []).map(item => ({ ...item, isAssigned: true })),
            ]
          : productsByLocations?.[selectedLocation] || []
      }
      emptyMessage={t('universal-search.empty-list')}
    />
  </PageContentAtom>
  </>
}
