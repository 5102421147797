import {useTranslation} from "react-i18next";
import {AtlasBodySubtitle, AtlasColor, CardComponent, CardRow, Divider, Icon, Icons, PageContentAtom, ToolbarMolecule} from "@justomx/atlas-components";
import {AlertCounts, useUniversalSearchLocation} from "./universal-search-location.hook";
import LoaderAtom from "../../../components/atoms/Loader.atom";
import { ProductWithStock } from "../components/product-with-stock.component";
import { FlexRowAtom } from "components/atoms/FlexRow.atom";
import { AlertIndicatorAtom } from "components/atoms/AlertIndicator.atom";
import EmptyListComponent from "components/molecules/EmptyList.molecule";

interface ContainerWithAlertsProps {
  container: string
  alerts: AlertCounts
}

const ContainerWithAlerts = ({container, alerts}: ContainerWithAlertsProps) => {
  return (
    <CardComponent $variant='highlight' style={{ marginBottom: '12px'}}>
      <CardRow style={{ justifyContent: 'space-between' }}>
        <div>
        <FlexRowAtom style={{ gap: 8}}>
          <Icon src={Icons.IcPallet} size={18}/>
          <AtlasBodySubtitle color={AtlasColor.Neutral00}>
            {container}
          </AtlasBodySubtitle>
        </FlexRowAtom> 
        </div>
        {alerts && (
          <FlexRowAtom style={{ gap: 8 }}>
            <AlertIndicatorAtom type="urgent">
              {alerts.urgent}
            </AlertIndicatorAtom>
            <AlertIndicatorAtom type="critical">
              {alerts.critical}
            </AlertIndicatorAtom>
            <AlertIndicatorAtom type="warning">
              {alerts.warning}
            </AlertIndicatorAtom>
          </FlexRowAtom>
        )}
      </CardRow>
    </CardComponent>
  )
}


export const UniversalSearchLocationPage = () => {
  const {location, containers, loading, goBack} = useUniversalSearchLocation()
  const {t} = useTranslation('global')

  const locationTypesMap = {
    Storage: t('universal-search.storage'),
    PreStorage: t('universal-search.prestorage'),
    Picking: t('universal-search.picking'),
  }

  return <>
    {loading && <LoaderAtom>{t('loader.message')}</LoaderAtom>}
    <ToolbarMolecule 
      title={t('universal-search.location.toolbar-title', {type: locationTypesMap[location?.type as string]})}
      actionIcon={Icons.ArrowBack}
      onAction={goBack}
    />
    <PageContentAtom>
      <CardComponent $variant='highlight' style={{ marginBottom: '12px'}}>
        <AtlasBodySubtitle>{t('universal-search.location.scanned-location')}</AtlasBodySubtitle>
        <FlexRowAtom style={{ gap: 8}}>
          <Icon src={Icons.IcUbicacionIcon} size={18}/>
          <AtlasBodySubtitle color={AtlasColor.Neutral00}>
            {location.name}
          </AtlasBodySubtitle>
        </FlexRowAtom>
      </CardComponent>
      {Object.entries(containers).map(([container, values]) =>(
          <div>
            {container !== 'unknown' ?
              <ContainerWithAlerts container={container} alerts={values.alerts}/>
              :
              <AtlasBodySubtitle style={{ marginBottom: '12px' }}>Productos sueltos en ubicación</AtlasBodySubtitle>
            }
              {values.products.length > 0 ?
              values.products.map((e,i) => <ProductWithStock key={e.productEan+'-'+e.location+'-'+i} product={e}/>)
            :
              <EmptyListComponent text={t('universal-search.empty-list')} />
            }
            <Divider />
          </div>
        )
      )}
    </PageContentAtom>
  </>
}
