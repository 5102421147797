import {AtlasBodySubtitle, CardComponent, CardRow, Icon, Icons, StackAtom, Divider, AtlasBodyMain} from "@justomx/atlas-components";
import { useTranslation } from "react-i18next";
import { ProductWithLocationInfo } from "../universal-search.types";

type LocationAndContainerWithStockProps = {
  item: ProductWithLocationInfo
  showStock?: boolean
  isAssigned?: boolean
  i: number
}

export function LocationAndContainer({item, showStock, isAssigned, i}: LocationAndContainerWithStockProps) {
  const {t} = useTranslation('global')
  return <CardComponent key={i + "-" + item.location + "-" + item.container} style={{ border: 'none'}}>
    <StackAtom style={{flexDirection: 'column', alignItems: 'baseline'}} gap={12}>
      <StackAtom>
        {item.container && <CardRow>
          <Icon src={Icons.IcPallet} size={18}/>
          <AtlasBodySubtitle>{item.container}</AtlasBodySubtitle>
        </CardRow>}
        <CardRow>
          <Icon src={Icons.IcUbicacionIcon} size={18}/>
          <AtlasBodyMain style={{ fontWeight: 700}}>{item.location}</AtlasBodyMain>
        </CardRow>
      </StackAtom>
      {showStock &&
        <> 
        <Divider style={{ color: '#3d3d3d'}} color="#3d3d3d"/>
        <CardRow style={{ width: '100%', justifyContent: 'space-between', color: '#C2C2C2'}}>
          <AtlasBodyMain style={{ fontWeight: 700 }}>{t('universal-search.quantity')}</AtlasBodyMain>
          <AtlasBodyMain style={{ fontWeight: 700 }}>{item.stock} {item.isWeighable ? t('uom.grams') : t('uom.units')}</AtlasBodyMain>
        </CardRow>
        {!isAssigned && <span style={{ backgroundColor: '#FF985C', padding: '4px 8px', color: '#333333', borderRadius: '4px' }}>Ubicación  no asignada</span>}
        </>
      }
    </StackAtom>
  </CardComponent>;
}

