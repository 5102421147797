import styled from 'styled-components'

export const StackAtom = styled.div<{
  $centered?: boolean
  color?: string
  gap?: number
  direction?: 'row' | 'column'
}>`
  flex-grow: 1;
  display: flex;
  flex-direction: ${({ direction }) => direction ?? 'column'};
  color: ${({ color }) => color ?? 'inherit'};
  gap: ${({ gap }) => gap ?? 8}px;
  ${({ $centered }) => {
    if ($centered) {
        return `
                justify-content: center;
                align-items: center;
            `
    }
    return ''
}}
`
