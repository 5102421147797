import { AtlasBodyMain, AtlasColor, PageContentAtom } from '@justomx/atlas-components';
import EmptyListImg from '../../assets/images/Utilities/Empty_list.svg';

const EmptyListComponent = ({text}: {text: string}) => {
  return(
    <div style={{textAlign: 'center'}}> 
      <img src={EmptyListImg} alt="empty-list" />
      <AtlasBodyMain color={AtlasColor.Neutral10}>{text}</AtlasBodyMain>
    </div>
  )

}

export default EmptyListComponent;