import {useNavigate} from 'react-router-dom'
import {useState} from 'react'
import {toast} from 'react-toastify'
import {adjustInventoryClient} from '../InventoryFix.client'
import {Analytics} from "../../../analytics/analytics.events";
import {useGoToInventoryProductList} from "../product-list/InventoryProductList.navigator";
import { useAtom } from 'jotai';
import { inventoryFixOriginInfoAtom, inventoryFixSelectedProductReader } from '../InventoryFix.state';

function canConfirmFix (quantity: string, currentStock: number) {
  const strQuantity = quantity.toString().trim()
  const numberQuantity = Number(quantity)

  return strQuantity !== '' &&
  numberQuantity !== currentStock &&
  strQuantity === numberQuantity.toString()
    ? {}
    : { disabled: true } // TODO: Move this to a better place
}

export const useInventoryProductFix = () => {
  const navigate = useNavigate()

  const inventoryProductListPage = useGoToInventoryProductList();
  const [quantity, setQuantity] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const [origin] = useAtom(inventoryFixOriginInfoAtom)
  const [selectedProduct] = useAtom(inventoryFixSelectedProductReader)
  
  return {
    canConfirm: canConfirmFix(quantity, selectedProduct.stock),
    value: selectedProduct,
    actions: {
      confirm: async () => {
        try {
          setLoading(true)
          const source = origin?.container?.name || origin?.location?.name as string 
          await adjustInventoryClient({
            ean: selectedProduct.productEan,
            source,
            quantity: Number(quantity)
          })

          Analytics.inventoryFix.adjustInventory({
            location: origin?.location?.legacyId as string,
            zone: origin?.location?.zone as string,
            count: Number(quantity),
            ean: selectedProduct.productEan,
            warehouse: selectedProduct.warehouse
          })

          toast.success('Cantidad actualizada del SKU: ' + selectedProduct.productSku + ' a ' + quantity)
          setTimeout(
            () => {
              setLoading(false)
              inventoryProductListPage.go(origin!)
            },
            2000
          )
        } catch (e) {
          console.error('On confirm inventory adjustment', e)
          toast.error(`Hubo un problema al ajustar el inventario. "${e.message ?? ''}`)
          setLoading(false)
        }
      },
      goBack: () => {
        navigate(-1)
      },
      setQuantity
    },
    origin,
    loading
  }
}
