import { useContainerDetail } from './ContainerDetail.hook'
import {
  BottomGroupMolecule,
  CardRow,
  PageContentAtom,
  PrimaryButtonAtom
} from '@justomx/atlas-components'
import { useTranslation } from 'react-i18next'

import { InventoryProductList } from '../../../design-system/organism/v2/InventoryProductList.organism'
import { SelectAllAtom } from 'components/atoms/SelectAll.atom'
import { MenuWrapperOrganism } from 'components/organisms/MenuWrapper.organism'
import LoaderAtom from 'components/atoms/Loader.atom'
import { MyFiller } from 'components/atoms/Filler'

export function StorageContainerDetailPage() {
  const { t } = useTranslation('global')
  const { products, actions, selectedInventoryProducts, isLoading } =
    useContainerDetail()

  const hasSelected = Boolean(selectedInventoryProducts.length)

  if (isLoading) {
    return <LoaderAtom>{t('loader.message')}</LoaderAtom>
  }

  return (
    <>
      <MenuWrapperOrganism title={t('to-storage.title')} />
      <PageContentAtom>
        <CardRow>
          <SelectAllAtom
            hasSelected={hasSelected}
            onAll={actions.selectAllInventoryProducts}
            onNone={actions.clearSelectedInventoryProducts}
          />
        </CardRow>
        <InventoryProductList
          products={products}
          selectable={true}
          selectedInventoryProducts={selectedInventoryProducts}
          onSelect={actions.toggleInventoryProduct}
        />
        <MyFiller />
      </PageContentAtom>
      <BottomGroupMolecule>
        <PrimaryButtonAtom
          data-test={'continue-btn'}
          onClick={() => {
            actions.continue()
          }}
          disabled={!selectedInventoryProducts.length}
        >
          {t('storage-container-detail.continue')}
        </PrimaryButtonAtom>
      </BottomGroupMolecule>
    </>
  )
}
