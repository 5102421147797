import { type RouteObject } from 'react-router-dom'
import { ALERTS_SCAN_PRODUCT_URL } from './v2/scan-product/ScanProduct.navigator'
import { ALERTS_REGISTER_RESTOCK_URL } from './v2/register-restock/RegisterRestock.navigator'
import { ALERTS_HOME_PAGE } from './Alerts.navigator'
import { AlertsRegisterRestockPage } from './v2/register-restock/RegisterRestock.page'
import { AlertsScanProductPage } from './v2/scan-product/ScanProduct.page'
import { ALERTS_REGISTER_INVENTORY_COUNT_URL } from './v2/register-inventory-count/RegisterInventoryCount.navigator'
import { AlertsRegisterInventoryCountPage } from './v2/register-inventory-count/RegisterInventoryCount.page'
import AlertsV2Page from './v2/Alerts.page'

export const alertsRoutes: RouteObject[] = [
  {
    id: '(alerts) Main Page - Container list',
    path: ALERTS_HOME_PAGE,
    element: <AlertsV2Page/>
  },
  {
    id: '(alerts) - Productos',
    path: ALERTS_SCAN_PRODUCT_URL,
    element: <AlertsScanProductPage/>
  },
  {
    id: '(alerts) - Reabastecer',
    path: ALERTS_REGISTER_RESTOCK_URL,
    element: <AlertsRegisterRestockPage/>
  },
  {
    id: '(alerts) - Conteo de producto',
    path: ALERTS_REGISTER_INVENTORY_COUNT_URL,
    element: <AlertsRegisterInventoryCountPage/>
  }
]
