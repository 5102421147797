import {useScanner} from "../../hooks/scanner.hook";
import {useEffect, useState} from "react";
import {getScannedInfo} from "../../client/scanner/scanner.client";
import {isScannedContainer, isScannedLocation, isScannedProduct} from "../../client/scanner/scanner.types";
import {onErrorToast} from "../../onErrorToast.util";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { universalSearchContainerAtom, universalSearchLocationAtom, universalSearchProductAtom, universalSearchScanAtom } from "./universal-search.state";
import { useConfirmationDialog } from "components/molecules/ConfirmationDialog.molecule";

export const useUniversalSearch = () => {
  const {keys, getScan} = useScanner()
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const [,setScanned] = useAtom(universalSearchScanAtom)
  const [,setSelectedProduct] = useAtom(universalSearchProductAtom)
  const [,setSelectedLocation] = useAtom(universalSearchLocationAtom)
  const [,setSelectedContainer] = useAtom(universalSearchContainerAtom)

  const {
    dialogRef: keyboardSearchDialogRef,
    open: openKeyboardSearchDialog
  } = useConfirmationDialog()

  const handleScanOrSearch = (value) => {
    setLoading(true)
    getScannedInfo(value)
        .then(e => {
          setScanned(e)
          if(isScannedProduct(e)) {
            setSelectedProduct(e.product)
            navigate(`/universal-search/product`)
          } 
          if(isScannedContainer(e)) {
            setSelectedContainer(e.container)
            setSelectedLocation(e.location)
            navigate(`/universal-search/container`)
          }
          if(isScannedLocation(e)) {
            setSelectedLocation(e.location)
            navigate(`/universal-search/location`)
          }
        })
        .catch(e => {
          onErrorToast('Error al escanear ' + value + ": " + e.message)
          console.error(e)
        })
        .finally(() => setLoading(false))
      }

  useEffect(() => {
    const scanned = getScan() 
    if (scanned) {
      handleScanOrSearch(scanned)
    }
  }, [keys, getScan])

  return {
    loading,
    handleScanOrSearch,
    keyboardSearchDialogRef,
    openKeyboardSearchDialog
  }
}
