import { atomWithStorage } from 'jotai/utils'
import { createReader } from '../../../utils/atomReader'
import { type ProductWithStock, type AlertSuggestionProduct, type ActiveRestockInventoryCount } from 'types/model'

export const AlertsSelectedAtom = atomWithStorage<AlertSuggestionProduct | undefined>('RestockingAlertsSelected', undefined)
export const AlertsSelectedReader = createReader(AlertsSelectedAtom)

export const ProductSelectedAtom = atomWithStorage<ProductWithStock | undefined>('RestockingProductSelected', undefined)
export const ProductSelectedReader = createReader(AlertsSelectedAtom)

export const ActiveRestockInventoryCountAtom = atomWithStorage<ActiveRestockInventoryCount | undefined>('ActiveRestockInventoryCount', undefined)

