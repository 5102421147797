import {
  type ScannedContainer,
  type ScannedLocation,
  ScanType
} from '../../../client/scanner/scanner.types'
import { LocationType } from '../../../client/types'

export function validateOrigin(
  origin: ScannedLocation | ScannedContainer,
  hasLooseProducts: boolean
) {
  if (origin.location.type === LocationType.Receipt) {
    throw Error(
      'O-1: No se puede escanear una ubicación o contenedor en ubicación de recibo'
    )
  }

  if (origin.type === ScanType.Location && !hasLooseProducts) {
    if (
      origin.location.type === LocationType.Storage &&
      origin.location.zone === 'Groceries' &&
      origin.totalContainers === 0
    ) {
      throw Error(
        'O-3: No se puede escanear una ubicación de almacenamiento sin contenedor'
      )
    }

    if (
      origin.location.type === LocationType.PreStorage &&
      origin.totalContainers === 0
    ) {
      throw Error(
        'O-5: No se puede escanear una ubicación de pre-almacenamiento sin contenedor'
      )
    }
  }

  return origin
}
