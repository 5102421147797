import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAtom } from 'jotai'
import { ActiveRestockInventoryCountAtom } from 'pages/alerts/v2/Alerts.state'
import { ALERTS_REGISTER_INVENTORY_COUNT_URL } from 'pages/alerts/v2/register-inventory-count/RegisterInventoryCount.navigator'

export function NavigationHandler ({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate()
  const [activeRestockInventoryCountAtom] = useAtom(ActiveRestockInventoryCountAtom)

  useEffect(() => {
    if (activeRestockInventoryCountAtom) {
      navigate(ALERTS_REGISTER_INVENTORY_COUNT_URL
        .replace(':source', activeRestockInventoryCountAtom.source)
        .replace(':ean', activeRestockInventoryCountAtom.ean)
        .replace(':destination', activeRestockInventoryCountAtom.destination))
    }
  }, [activeRestockInventoryCountAtom, navigate])

  return <>{children}</>
}
