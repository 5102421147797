import { createBrowserRouter } from 'react-router-dom'
import { HomePage } from './home/Home.layout'
import { HomeListPage } from './home/home-list/HomeList.page'
import ErrorPage from './error/Error.page'
import { transferRoutes } from './traslation/routes'
import { inventoryFixRoutes } from './inventory-fix/routes'
import { fastTraslationRoutes } from './fast-traslation/FastTraslation.routes'
import { containerAdministrationRoutes } from './container-administration/ContainerAdministration.routes'
import { alertsRoutes } from './alerts/Alerts.routes'
import { productRouter } from './product/product.router'
import { storageRoutes } from './storage/routes'
import { inventoryWasteRoutes } from './inventory-waste/routes'
import { universalSearchRoutes } from './universal-search/routes'
import { reverseLogisticsRoutes } from './reverse-logistics/routes'
import { NavigationHandler } from './../NavigationHandler'

export const router = createBrowserRouter([
  {
    element: <NavigationHandler>
      <HomePage />
    </NavigationHandler>,
    errorElement: <ErrorPage/>,
    children: [
      {
        index: true,
        element: <HomeListPage/>
      },
      ...transferRoutes,
      ...inventoryFixRoutes,
      ...containerAdministrationRoutes,
      ...alertsRoutes,
      ...storageRoutes,
      ...inventoryWasteRoutes,
      ...universalSearchRoutes,
      ...reverseLogisticsRoutes
    ]
  }

])
