import {useEffect, useState} from "react";
import {ProductDetailsResponse} from "../../../client/products/products.types";
import { useAtom } from "jotai";
import { universalSearchProductAtomReader } from "../universal-search.state";
import { useNavigate } from "react-router-dom";
import { getLocationAssignedToProduct } from "client/location-assignment/location-assignment.client";
import { getProductDetails } from "client/inventory/inventory.client";
import { ProductWithLocationInfo } from "../universal-search.types";

export function useUniversalSearchProduct() {
  const [product] = useAtom(universalSearchProductAtomReader)
  const [productsByLocations, setProductsByLocations] = useState<ProductDetailsResponse | null>(null)
  const [assignedLocations, setAssignedLocations] = useState<ProductWithLocationInfo[] | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
      setLoading(true)
      const productPromise = getProductDetails(product.ean)
      .then(products => {
        const productsGroupedByLocations = 
          products.filter(p => p.locationType !== 'Rack' && p.locationType !== 'Receipt' && p.locationType !== 'ReverseLogistics').reduce<ProductDetailsResponse>(
          (acc, product) => {
              const locationKey = product.locationType as keyof ProductDetailsResponse;
              acc[locationKey].push(product);
              return acc;
          },
          { Picking: [], PreStorage: [], Storage: [], Preparation: [] }
        );
        setProductsByLocations(productsGroupedByLocations)
      })

      const assignedLocations = getLocationAssignedToProduct(product.ean)
      .then(e => {
        const formattedLocations: ProductWithLocationInfo[]  = e.locations.map(location => ({
          productEan: e.ean,
          stock: 0,
          location: location.name,
          locationType: location.type,
          locationZone: location.zone,
          container: undefined
        }))
        setAssignedLocations(formattedLocations)
      })
      .catch(e => console.error(e))

      Promise.all([productPromise, assignedLocations]).finally(
        () => setLoading(false)
      )
    }, [product]
  )

  return {
    product: {
      productName: product.name,
      productEan: product.ean,
      productSku: product.sku,
      productImage: product.image as string,
      stock: 0,
      location: '',
      locationType: '',
      locationZone: '',
      container: '',
      country: '',
      warehouse: '',
      isWeighable: product.isWeighable
    },
    productsByLocations,
    assignedLocations,
    loading,
    goBack: () => navigate(-1)
  }
}
