import { useEffect } from 'react'
import { useScanner } from '../../../../hooks/scanner.hook'
import { isValidLPNScan } from '../../../../utils/isValidLpn.util'
import { useNavigate } from 'react-router-dom'
import { useConfirmLiftNavigator } from '../confirm-lift/ConfirmLift.navigator'
import { getContainerFromScan, getScannedInfo } from 'client/scanner/scanner.client'
import { toCommonLocationInfoFromScanResponse } from 'utils/commonLocationInfo'
import { useAtom } from 'jotai'
import { containerAdministrationContainerToLift } from 'pages/container-administration/ContainerAdministration.state'
import { LocationInfo } from 'types/model'

export const useScanContainerToLift = (onError: (err: string) => void) => {
  const { keys, getScan } = useScanner()
  const navigate = useNavigate()
  const confirmLiftNavigator = useConfirmLiftNavigator()
  const [, setContainerToLift] = useAtom(containerAdministrationContainerToLift)
  
  useEffect(() => {
    const scanned = getScan()
    if (scanned) {
      if (isValidLPNScan(scanned)) {
        getContainerFromScan(scanned)
          .then(res => {
            const formattedOrigin: LocationInfo = {
              location: res.location.name,
              locationType: res.location.type,
              container: res.container.name
            }
            setContainerToLift(formattedOrigin)
            confirmLiftNavigator.go(formattedOrigin.container)
          })
          .catch(e => {
            console.error(e)
            onError('Hubo un error al escanear el contenedor: ' + e.message)
          })
      } else {
        onError(`${scanned} no es un LPN válido`)
      }
    }
  }, [keys])

  return {
    actions:{
      goBack: () => {
        navigate(-1)
      }
    }
  }

}
