import {useAtom} from "jotai/index";
import {useEffect, useRef, useState} from 'react'
import {toast} from "react-toastify";
import {useScanner} from '../../../hooks/scanner.hook'
import {createInventoryWasteReportClient} from "../Inventory-waste.client";
import {inventoryWasteOriginInfoReader, inventoryWasteReportReader, selectedProductReader} from "../inventory-waste.state";
import {useInventoryWasteScanOrigin} from "../scan-origin/ScanOrigin.navigator";
import {validateDestination} from "./ScanDestination.validator";
import { useNavigate } from "react-router-dom";
import { Analytics } from "analytics/analytics.events";

export const useScanDestination = () => {
  const { keys, getScan } = useScanner()
  const [product,] = useAtom(selectedProductReader)
  const [locationOrigin] = useAtom(inventoryWasteOriginInfoReader)
  const [inventoryWasteReport] = useAtom(inventoryWasteReportReader)
  const scanOrigin = useInventoryWasteScanOrigin()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const scanExecutedRef = useRef(false)

  useEffect(() => {
    const scanned = getScan()
    if (scanned && !loading && !scanExecutedRef.current) {
      scanExecutedRef.current = true;
      setLoading(true)
      if (validateDestination(scanned)) {
        const reportData = {
          "productEan": product?.productEan!,
          "productSku": product?.productSku!,
          "productName": product?.productName!,
          "productImage": product?.productImage!,
          "quantity": inventoryWasteReport?.quantity!,
          "locationName": locationOrigin.location?.name as string,
          "containerName": locationOrigin?.container?.name,
          "reason": inventoryWasteReport?.reason
        }
        try {
          createInventoryWasteReportClient(reportData)
            .then(() => {
                Analytics.inventoryWaste.createInventoryWasteReport({reportData})
                toast.success('Cantidad reporta del SKU: ' + product?.productSku)
                setTimeout(
                    () => {
                      scanOrigin.go()
                    },
                    2000
                )
            })
            .catch((err) => {
                console.error('Error on create inventory waste report', err)
                toast.error('Error al crear nuevo reporte de merma. ' + err?.response.data.detail)
            })
            .finally(() => {
              setLoading(false)
              scanExecutedRef.current = false;
            })
        } catch (e) {
          console.error('Error on create inventory waste', e)
          toast.error('Error al crear nuevo reporte de merma.' + e?.message)
          setLoading(false)
        }
      } else {
        toast.error(`No se puede escanear una ubicación que no sea de merma`)
        setLoading(false)
        scanExecutedRef.current = false;
      }
    }
  }, [keys])

  return {
    actions: {
      goBack: () => { navigate(-1) },
    },
    loading
  }
}
