import { useTranslation } from 'react-i18next'
import {
  AtlasBodyMain,
  AtlasBodySubtitle,
  AtlasH3,
  AtlasListAtom,
  CardComponent,
  Filler,
  Icons,
  PageContentAtom,
  PrimaryButtonAtom,
  SpacerAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'
import ChangeWeightMolecule from 'components/molecules/ChangeWeight.molecule'
import ListOfCounts from 'components/molecules/ListOfCounts.molecule'
import { formatWeight } from 'utils/product'
import { formatString } from 'utils/string'
import { FlexColumnAtom } from 'components/atoms/FlexColumn.atom'
import LoaderAtom from 'components/atoms/Loader.atom'
import { useRegisterInventoryCount } from './RegisterInventoryCount.hook'
import { ProductCard } from 'components/molecules/v2/ProductCard.molecule'
import { LocationAssignmentsMolecule } from '../../../../components/molecules/LocationAssignments.molecule'
import { ConfirmationDialogMolecule } from '../../../../components/molecules/ConfirmationDialog.molecule'

export const AlertsRegisterInventoryCountPage = () => {
  const { t } = useTranslation('global')

  const {
    isLoading,
    product,
    counts,
    totalCounts,
    weightToDelete,
    destination,
    deleteWeightDialogRef,
    actions
  } = useRegisterInventoryCount()

  if (isLoading) {
    return <LoaderAtom>{t('loader.message')}</LoaderAtom>
  }

  if (!product) {
    return <AtlasH3>Error al obtener la información del producto</AtlasH3>
  }

  return <>
    <ToolbarMolecule
      title={t('alerts.register-inventory-count.toolbar-title')}
      actionIcon={Icons.IcTaskIcon}
    />
    <PageContentAtom>
      <AtlasBodySubtitle>{t('alerts.register-inventory-count.instructions')}</AtlasBodySubtitle>
      <AtlasListAtom style={{listStyleType: 'decimal'}}>
        <li ><AtlasBodyMain display="contents">{t('alerts.register-inventory-count.count-product')}</AtlasBodyMain></li>
        <li><AtlasBodyMain display="contents">{t('alerts.register-inventory-count.scan-or-write')}</AtlasBodyMain></li>
        <li><AtlasBodyMain display="contents">{t('alerts.register-inventory-count.select-register')}</AtlasBodyMain></li>
      </AtlasListAtom>
      <CardComponent $variant="highlight" style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#47474733'
      }}>
        <ProductCard
          product={{ ...product, zone: '' }}
          showTitle={true}
          title={t('alerts.register-inventory-count.product-to-count')}
          bgColor='#424242'
        /> 
        <LocationAssignmentsMolecule locations={[destination]} title={t('alerts.register-inventory-count.destination-location')}/>
        <SpacerAtom height={20} />
        <FlexColumnAtom style={{width: '100%'}}>
              <ChangeWeightMolecule
                onAdd={(value) => {
                  actions.addItem(value)
                }}
                label={t('alerts.register-inventory-count.input-label')}
              />
        </FlexColumnAtom>
      </CardComponent>
      <ListOfCounts
        detail={formatString(t('inventory-waste.register-waste.total-qty'), product.isWeighable ? formatWeight(totalCounts, 2) : `${totalCounts} uds`)}
        isWeighable={product.isWeighable}
        items={counts}
        onDelete={actions.onRemoveItem}
      />
      <Filler />
      <PrimaryButtonAtom
          disabled={isLoading || counts.length === 0}
          onClick={actions.registerInventoryCount}
      >
        Registrar
      </PrimaryButtonAtom>
      <ConfirmationDialogMolecule
        dialogRef={deleteWeightDialogRef}
        confirmText={t('fast-set-weight.dialog-delete.btn-confirm')}
        onConfirm={actions.confirmRemoveItem}
        cancelText={t('fast-set-weight.dialog-delete.btn-cancel')}>
        {
          formatString(t('fast-set-weight.dialog-delete.msg'), formatWeight(weightToDelete))
        }
      </ConfirmationDialogMolecule>
    </PageContentAtom>
  </>
}
