import { useState } from 'react'

export const useCountSystem = (initialCount?: number | null) => {
  const [counts, setCounts] = useState<number[]>(initialCount != null ? [initialCount] : [])

  return {
    counts,
    total: counts.reduce((prev, acc) => prev + acc, 0),
    add: (count: number): void => {
      setCounts([...counts, count])
    },
    remove: (count: number) => {
      const index = counts.indexOf(count)
      if (index >= 0) {
        const temp = [...counts]
        temp.splice(index, 1)
        setCounts(temp)
      }
    }
  }
}
