import { useScanner } from '../../../../hooks/scanner.hook'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { useRequiredParams } from 'hooks/params.hook'
import { extractWeight } from 'utils/product'
import {
  getLocationOrContainerFromScan,
  getProductOrLocationFromScan
} from 'client/scanner/scanner.client'
import {
  ActiveRestockInventoryCountAtom,
  AlertsSelectedAtom,
  ProductSelectedAtom
} from '../Alerts.state'
import { useConfirmationDialog } from 'components/molecules/ConfirmationDialog.molecule'
import { restock } from 'client/alerts/alerts.client'
import { getLocationAssignedToProduct } from 'client/location-assignment/location-assignment.client'
import { type ProductLocation } from 'client/location-assignment/location-assignment.types'
import { getStock } from '../../../../client/inventory/inventory.client'
import { useNavigate } from 'react-router-dom'
import { waiting } from '../../../../utils/waiting'
import { useCountSystem } from '../../../../hooks/counts.hook'
import {
  ScannedContainer,
  ScannedLocation,
  ScanType
} from '../../../../client/scanner/scanner.types'
import { Analytics } from 'analytics/analytics.events'
import { useAlertsRegisterInventoryCountNavigator } from '../register-inventory-count/RegisterInventoryCount.navigator'
import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'
import { DateTime } from 'luxon';

type WarehouseShift = {
  start: string;
  end: string;
  percentage: number;
};

export const useRegisterRestock = () => {
  const { t } = useTranslation('global')
  const { keys, getScan } = useScanner()
  const { ean } = useRequiredParams('ean')
  const { source } = useRequiredParams('source')
  const navigate = useNavigate()
  const initialWeight = extractWeight(ean)
  const weightSystem = useCountSystem(initialWeight)
  const restockInventoryCountNavigator = useAlertsRegisterInventoryCountNavigator()
  const isInventoryCountOn = useFeatureIsOn('op_restock_inventory_count')
  const warehouseShifts = useFeatureValue<WarehouseShift[]>('op-restock-inventory-count-config', []);

  const restockDialog = useConfirmationDialog<number>()
  const deleteWeightDialog = useConfirmationDialog<number>()

  const [alert] = useAtom(AlertsSelectedAtom)
  const [product] = useAtom(ProductSelectedAtom)
  const [, setActiveRestockInventoryCount] = useAtom(ActiveRestockInventoryCountAtom)

  const [loading, setLoading] = useState(false)
  const [suggestedLocations, setSuggestedLocations] = useState<ProductLocation[]>()
  const [destination, setDestination] = useState<ScannedLocation | null>(null)
  const [stock, setStock] = useState<number>(0)
  const [origin, setOrigin] = useState<ScannedLocation | ScannedContainer | null>(null)
  const [quantityToRestock, setQuantityToRestock] = useState<number>(0)

  const getInventoryCountPercentage = () => {
    const now = DateTime.now();
    
    for (const shift of warehouseShifts) {
      const startTime = DateTime.fromFormat(shift.start, 'HH:mm');
      const endTime = DateTime.fromFormat(shift.end, 'HH:mm');
  
      if (
        (startTime < endTime && now >= startTime && now < endTime) || 
        (startTime > endTime && (now >= startTime || now < endTime))
      ) {
        return shift.percentage;
      }
    }
  
    return 0.05;
  };

  /* On init: find source info for Analytics event  */
  useEffect(() => {
    getLocationOrContainerFromScan(source)
      .then(setOrigin)
      .catch((e) => {
        console.error(e)
        toast.error('Error getting source location: ' + e.message)
      })
  }, [])

  /* On init: find location assignments for product  */
  useEffect(() => {
    if (product) {
      setLoading(true)
      Promise.all([getLocationAssignedToProduct(product.ean), getStock(product.ean, source)])
        .then(([locations, stock]) => {
          setSuggestedLocations(locations.locations)
          setStock(stock)
        })
        .catch((e) => {
          console.error(e)
          toast.error('Error getting suggested locations:' + e.message)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [product])

  /* On scan */
  useEffect(() => {
    const textScanned = getScan()
    if (!textScanned || loading) {
      return
    }

    getProductOrLocationFromScan(textScanned)
      .then((scanned) => {
        if (scanned.type === ScanType.Product) {
          const weight = extractWeight(textScanned)
          if (weight) {
            weightSystem.add(weight)
          }
        } else {
          if (suggestedLocations?.some((l) => l.name === textScanned)) {
            setDestination(scanned)
          } else {
            toast.error(t('alerts.register.error-location-not-suggested'))
          }
        }
      })
      .catch((e) => {
        toast.error('Error al escanear ' + textScanned + ': ' + e.message)
        console.error(e)
      })
  }, [keys])

  /* On change destination: restock product */
  useEffect(() => {
    if (!destination || loading) {
      return
    }

    const quantity = product?.isWeighable ? weightSystem.total : quantityToRestock
    if (quantity <= 0) {
      toast.error(t('alerts.register.quantity-error'))
      setDestination(null)
      return
    }

    if (quantity > stock) {
      toast.error(t('alerts.register.stock-error'))
      setDestination(null)
      return
    }

    if (alert && quantity < alert.expectedStock) {
      restockDialog.open()
    } else {
      registerRestock(quantity)
    }
  }, [destination])

  const registerRestock = (quantity: number, partialRestockReason?: string) => {
    setLoading(true)
    restock({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ean: product!.ean,
      source,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      target: destination?.location.name!,
      quantity,
      description: partialRestockReason
    })
      .then(async () => {
        toast.success(
          t('alerts.register.success', {
            quantity,
            uom: product?.isWeighable ? 'g' : 'uds',
            product: product?.name ?? 'NN'
          })
        )
        Analytics.restock({
          count: quantity,
          ean: product?.ean as string,
          origin: {
            locationName: origin?.location.name as string,
            locationType: origin?.location.type as string,
            containerName: origin?.container?.name as string,
            containerId: origin?.container?.id as string
          },
          destination: {
            locationName: destination?.location.name as string,
            locationType: destination?.location.type as string
          },
          alertLevel: alert?.level as string,
          partialRestockReason
        })
        await waiting(3_000)
        const pickingCount = suggestedLocations?.filter((l) => l.type === 'Picking').length ?? 0
        const prepCount = suggestedLocations?.filter((l) => l.type === 'Preparation').length ?? 0
        const hasValidLocation = pickingCount === 1 || (pickingCount === 0 && prepCount === 1)
        const percentage = getInventoryCountPercentage()
        const needsInventoryCount = Math.random() < percentage && hasValidLocation && alert?.level !== 'Urgent'
        if (needsInventoryCount && isInventoryCountOn) {
          setActiveRestockInventoryCount({
            ean: product?.ean as string,
            source,
            destination: destination?.location.name as string,
            alert: alert!,
            product: product!,
            startedAt: new Date().toISOString()
          })
          restockInventoryCountNavigator.go(source, ean, destination?.location.name!)
        } else {
          navigate(-1)
        }
      })
      .catch((err: Error) => {
        toast.error('Error al registrar restock: ' + err.message)
        console.error(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    isLoading: loading,
    stock,
    expectedStock: product?.isWeighable
      ? Math.max(0, (alert?.expectedStock ?? 0) - weightSystem.total)
      : alert?.expectedStock ?? 0,
    suggestedLocations,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    product,
    alert,
    weights: weightSystem.counts,
    totalWeight: weightSystem.total,
    setQuantityToRestock,
    partialRestockDialogRef: restockDialog.dialogRef,
    deleteWeightDialogRef: deleteWeightDialog.dialogRef,
    weightToDelete: deleteWeightDialog.value ?? 0,
    actions: {
      goBack: () => {
        navigate(-1)
      },
      addItem: (item: number) => {
        weightSystem.add(item)
      },
      onRemoveItem: (item: number) => {
        deleteWeightDialog.open(item)
      },
      confirmRemoveItem: () => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        weightSystem.remove(deleteWeightDialog.value!)
      },
      confirmRestock: (partialRestockReason) => {
        registerRestock(
          product?.isWeighable ? weightSystem.total : quantityToRestock,
          partialRestockReason
        )
      },
      clearDestination: () => {
        setDestination(null)
      }
    }
  }
}
