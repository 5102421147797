import styled from 'styled-components'

import { AtlasColor } from '../foundations/Colors.foundations'

export const Divider = styled.div<{ $height?: number; $color?: string }>`
  height: ${({ $height }) => $height ?? 1}px;
  border-radius: 1px;
  background-color: ${({ $color }) => $color ?? AtlasColor.Neutral60};
  width:100%;
`
