import { isEanWeighable } from 'utils/product'
import {
  hasNumberProperty,
  hasStringProperty,
  isObject
} from '../internal-types/IsObject'
import { type UomKey } from '../client/types'

export interface InventoryProduct {
  productId: string
  productName: string
  productSku: string
  productEan: string
  productImage?: string
  productZone?: string
  stock: number
  quantity?: number
  warehouse: string
  country: string
  byWeight: boolean
}

interface ResponseHasInventoryProducts {
  inventoryProducts: InventoryProduct[]
}

export const isInventoryProductsResponse = (
  o: unknown
): o is ResponseHasInventoryProducts => {
  return (
    isObject(o) &&
    'inventoryProducts' in o &&
    Array.isArray(o.inventoryProducts) &&
    o.inventoryProducts.every((e) => isInventoryProduct(e))
  )
}

// TODO: Replace with Zod
export const isInventoryProduct = (o: unknown): o is InventoryProduct => {
  return (
    isObject(o) &&
    hasStringProperty(o, 'productId') &&
    // hasStringProperty(o, 'productName') &&
    hasStringProperty(o, 'productSku') &&
    hasStringProperty(o, 'productEan') &&
    hasNumberProperty(o, 'stock')
  )
}

export interface Product {
  productId: string
  productName: string
  productSku: string
  productEan: string
  productImage: string
  productZone: string
  stock?: number
  isWeighable: boolean
}

export interface ProductInfoCard {
  ean: string
  sku: string
  name: string
  stock: number
  priority?: number
  zone: string
  isWeighable: boolean
  image?: string
}

export interface ProductTmp {
  legacyId: string
  name: string
  sku: string
  ean: string
  image: string
  zone?: string
  stock?: number
}

export const isProduct = (o: unknown): o is Product => {
  return (
    isObject(o) &&
    (hasStringProperty(o, 'productId') || hasNumberProperty(o, 'productId')) &&
    hasStringProperty(o, 'productName') &&
    hasStringProperty(o, 'productSku') &&
    hasStringProperty(o, 'productEan')
  )
}

export const isProductTmp = (o: unknown): o is Product => {
  return (
    isObject(o) &&
    (hasStringProperty(o, 'legacyId') || hasNumberProperty(o, 'legacyId')) &&
    hasStringProperty(o, 'name') &&
    hasStringProperty(o, 'sku') &&
    hasStringProperty(o, 'ean')
  )
}

/**
 * Weighable products.
 *
 * In EAN13, a weighable item starts with '2', and the ID is the first seven digits.
 * The rest of the digits are the weight of the product in grams.
 *
 * I.E.: 2012637 is Red Apple
 *
 * [ 2 1 0  -   2 6 3 7 - Y Y Y - Z Z Z ]
 *   ^^^^^      ^^^^^^^   ^^^^^   ^^^^^
 * Weighable   Internal   KG      Gram
 *   Prefix     Product   weight  weight
 *              Barcode
 *
 * @param expectedEan cropped or scanned EAN13
 * @param receivedEan cropped or scanned EAN13
 */
const isSameWeighableProduct = (expectedEan: string, receivedEan: string) =>
  isEanWeighable(expectedEan) &&
  (receivedEan.startsWith(expectedEan) || expectedEan.startsWith(receivedEan))

export const isSameProduct =
  (receivedEan: string) => (product: InventoryProduct) => {
    if (product.productEan === receivedEan) return true
    return isSameWeighableProduct(product.productEan, receivedEan)
  }

export const isSameEan = (expectedEan: string, receivedEan: string) => {
  if (expectedEan == null || receivedEan == null) {
    return false
  }
  return (
    expectedEan === receivedEan ||
    isSameWeighableProduct(expectedEan, receivedEan)
  )
}

export const normalizeEanForCapture = (ean: string) => {
  if (isEanWeighable(ean)) {
    // Is Weighable, so extract prefix + internal barcode
    return ean.substring(0, 7)
  }
  return ean
}

export const isEAN = (ean: string) => {
  if (/^\d+$/.test(ean)) {
    if (isEanWeighable(ean) && ean.length < 14) {
      return true
    }
    return ean.length === 13
  }

  return false
}

export interface Location {
  locationId: string
  locationName: string
  locationType: string
}

export interface LocationInfo {
  location: string
  locationType: string
  container?: string
  alerts?: { urgent: number; critical: number; warning: number }
}

export interface LocationAndContainer {
  locationId: string
  locationName: string
  locationType: string
  containerId?: string
  containerName?: string
  containersInLocation: number
  alerts?: { urgent: number; critical: number; warning: number }
}

/** @deprecated since version 2.0 */
export interface LocationOrContainer {
  location: {
    id: string
    name: string
    legacyId: string
    type: string
    country: string
    warehouse: string
    zone: string
  }
  container?: {
    id: string
    name: string
    legacyId: string
    country: string
    warehouse: string
  }
  totalContainers?: number
  type: string
  alerts?: { critical: number; warning: number }
}

export const isLocationAndContainer = (
  o: unknown
): o is LocationAndContainer => {
  return (
    isObject(o) &&
    hasStringProperty(o, 'locationId') &&
    hasStringProperty(o, 'locationName') &&
    hasStringProperty(o, 'locationType')
  )
}

export const isRequiredLocationAndContainer = (
  o: unknown
): o is LocationAndContainer => {
  return (
    isObject(o) &&
    hasStringProperty(o, 'locationId') &&
    hasStringProperty(o, 'locationName') &&
    hasStringProperty(o, 'locationType')
  )
}

const StorageZone = {
  HighValue: 'STK',
  Bulk: 'STG',
  Refrigerated: 'CR',
  Frozen: 'CC',
  Laboratory: 'SFV'
}

const LogicalContainerStorage = [
  StorageZone.HighValue,
  StorageZone.Refrigerated,
  StorageZone.Frozen,
  StorageZone.Laboratory
]

export const getLogicalContainerStorage = (warehouse?: string) => {
  if (warehouse === 'AT') {
    return LogicalContainerStorage
  }
  return [StorageZone.Bulk, ...LogicalContainerStorage]
}

export const isLPNRegex = /^[A-Z]{2,3}-(LPN|11[1-5])-[0-9]{8}$/

const isRackLocationRegex = /^TODO_RACK_LOCATION$/

export const isRackLocation = (barcode: string) => {
  return isRackLocationRegex.test(barcode)
}

const isLocationRegex = /^[A-Z]{2,3}-[A-Z]+-(\w+\d+-)+-U\d+$/

export const isLocation = (barcode: string) => {
  return isLocationRegex.test(barcode)
}

export const isLPN = (barcode: string) => {
  return isLPNRegex.test(barcode)
}

export const whatIsThisBarcode = (
  barcode: string
): 'lpn' | 'rack' | 'location' | 'ean' | 'unknown' => {
  if (isEAN(barcode)) {
    return 'ean'
  }

  if (isLPN(barcode)) {
    return 'lpn'
  }

  if (isRackLocation(barcode)) {
    return 'rack'
  }

  if (isLocation(barcode)) {
    return 'location'
  }

  return 'unknown'
}

export interface ProductDetail {
  productId: string
  totalUnits: number
  upperTotalLimit: number
  quantity: number
  barcode: string
  description: string
  status: string
  imgUrl: string
  unitsOfMeasurment: number
  measureUnit: string
  zone: string
  isFullMissing?: boolean
  totalReceived: number
}

export interface FormikRejectValues {
  amount: string
  lot: string
  reason: string[]
  boxes?: string
}

export interface FormikTemperatureValues {
  temperature: string
  outOfRangeApproved: boolean
}

export interface FormikInventoryWasteValues {
  amount: string
  reason: string[]
}

export interface FormikRegisterReverseLogicValues {
  amount: string
}

export interface GenericOption {
  id: string
  label: string
}

export interface InventoryWasteReport {
  quantity: number
  reason: string[]
}

export interface ProductInfo {
  id: string
  ean: string
  sku: string
  name: string
  image?: string
  isWeighable: boolean
  zone: string
  packagingLevels: Array<{
    name: string
    quantity: number
    uom: UomKey
  }>
}

export interface ProductWithStock extends ProductInfo {
  stock: number
}

export interface ProductDemand {
  ean: string
  method: string
  value: number
  type: string
  from: string
  country: string
  warehouse: string
}

export interface ProductWithAlertInfo extends ProductInfo {
  stock: number
  alert: string
}

export interface ReverseLogisticProductInfo {
  id?: string
  ean: string
  sku: string
  missingReports: []
  name: string
  pendingQuantity: number
  priority: number
  quantity: number
  status: string
  unpicks: []
  wasteReports: []
  zone: string
  isWeighable: boolean
  image?: string
  isSampling: boolean
}

interface Tare {
  id: string
  barcode: string
  orderNumber: string
}

export interface TareOrProduct {
  country: string
  createdAt: string
  id: string
  priority: number
  products: ReverseLogisticProductInfo[]
  status: string
  tara?: Tare
  type: string
  warehouse: string
}

export interface AlertSuggestionProduct {
  country: string
  createdAt: string
  expectedStock: number
  id: string
  level: string
  locationIds: string[]
  productEan: string
  productId: string
  productImage: string
  productName: string
  productSku: string
  isWeighable: boolean
  restocked: any[]
  state: string
  status: string
  suggestedContainers: SuggestedContainer[]
  suggestions: Suggestion[]
  type: string
  updatedAt: string
  warehouse: string
}

interface SuggestedContainer {
  id: string
  locationId: string
  quantityToTake: number
  stockInContainer: number
  isLogic: boolean
}

interface Suggestion {
  containerId: string
  containerName: string
  index: number
  locationId: string
  locationName: string
  locationType: string
  stock: number
}

export interface AlertStats {
  urgent: number
  critical: number
  warning: number
}

type Country = 'MX' | 'BR' | 'PE'

export interface WasteReason {
  reason: string
  displayName: Record<Country, string>
}

export interface ActiveRestockInventoryCount {
  ean: string
  source: string
  destination: string
  alert: AlertSuggestionProduct
  product: ProductWithStock
  startedAt: string
}
