import { LoggedUser, LoggedUserDTO } from "./user-managment.types";

declare global {
  interface Window {
    WV: {
      saveUserInfo: (user: string) => void;
      getUserInfo: () => string;
      logout: () => void;
    };
  }
}

const setCurrentUser = (user: LoggedUserDTO) => {
  const userParsed = new LoggedUser(user);
  window.WV.saveUserInfo(userParsed.serialize());
};

const getCurrentUser = (): LoggedUser => {
  const rawUserInfo = window.WV.getUserInfo();

  return LoggedUser.deserialize(rawUserInfo);
};

let onLogoutCallback: (() => void) | null = null;
let beforeLogoutCallback: (() => { cancelLogout: boolean } | void) | null =
  null;

const onLogout = (callback: () => void) => {
  onLogoutCallback = callback;
};

const beforeLogout = (callback: () => { cancelLogout: boolean } | void) => {
  beforeLogoutCallback = callback;
};

const logout = () => {
  if (beforeLogoutCallback) {
    const result = beforeLogoutCallback();
    if (result && result.cancelLogout) return;
  }

  window.WV?.saveUserInfo("");
  window.WV.logout();

  if (onLogoutCallback) {
    onLogoutCallback();
  }
};

export const UserManagment = {
  setCurrentUser,
  getCurrentUser,
  logout,
  onLogout,
  beforeLogout,
};
