import { useScanner } from '../../../hooks/scanner.hook'
import { useGoToMoveOptionsNavigator } from '../move-options/MoveOptions.navigator'
import { useEffect, useState } from 'react'
import { Global } from '../../../utils/global'
import { manageError } from '../../../utils/errors/error.handler'
import { validateOrigin } from './ScanOrigin.validator'
import { useGoToChooseContainerNavigator } from '../choose-container/ChooseContainer.navigator'
import { useAtom } from 'jotai'
import { transferHasLooseProducts, transferItemsAtom, transferLooseProductsAtom, transferOriginInfoAtom } from '../transfer.state'
import { getLocationOrContainerFromScan } from 'client/scanner/scanner.client'
import { getProducts } from '../../../client/inventory/inventory.client'
import { ScanType } from '../../../client/scanner/scanner.types'
import { LocationType, LocationZone } from '../../../client/types'
import { toast } from 'react-toastify'

export const useScanOrigin = () => {
  const {
    keys,
    getScan
  } = useScanner()
  const moveOptions = useGoToMoveOptionsNavigator()
  const chooseContainer = useGoToChooseContainerNavigator()
  const [, setOrigin] = useAtom(transferOriginInfoAtom)
  const [, setLooseProducts] = useAtom(transferLooseProductsAtom)
  const [hasLooseProducts] = useAtom(transferHasLooseProducts)
  const [, setProducts] = useAtom(transferItemsAtom)
  
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const scanned = getScan()
    if (scanned) {
      setIsLoading(true)
      getLocationOrContainerFromScan(scanned)
        .then(async (origin) => {
          const products = await getProducts(origin.container?.name ?? origin.location.name)
          if(products.length === 0) {
            throw Error('El contenedor esta vacio')
          }
          setProducts(products)
          if (origin.location.type === LocationType.PreStorage ||
            (origin.location.type === LocationType.Storage &&
              origin.location.zone === LocationZone.Groceries)) {
            const looseProducts = products.filter((it) => it.container == null)
            if (looseProducts.length > 0) {
              setLooseProducts(looseProducts)
            }
          }
          else {
            setLooseProducts([])
          }
          return origin
        })
        .then((origin) => validateOrigin(origin, hasLooseProducts))
        .then((origin) => {
          setOrigin(origin)
          if (origin.type === ScanType.Location && origin.container == null && origin.totalContainers > 1) {
            chooseContainer.go(origin)
          } else {
            moveOptions.go(origin)
          }
        })
        .catch((e) => {
          toast.error(e.message)
          manageError(`ScanOrigin.hooks.ts#useEffect ${scanned}`)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [keys])

  return {
    isLoading,
    actions: {
      openMenu: () => {
        Global.WV.openMenu()
      }
    }
  }
}
