import { AtlasBodySubtitle, AtlasColor, CardComponent, CardRow, SpacerAtom } from '@justomx/atlas-components'
import { FlexRowAtom } from 'components/atoms/FlexRow.atom'
import { DeleteIcon } from 'components/DeleteIcon'
import { formatWeight } from 'utils/product'

interface ListOfCountsProps {
  detail: string
  items: number[]
  isWeighable?: boolean
  onDelete: (item: number) => void
}

const ListOfCounts = ({
  detail,
  items,
  isWeighable=true,
  onDelete
}: ListOfCountsProps) => {
  return (
    <>
      <AtlasBodySubtitle>{detail}</AtlasBodySubtitle>
      <SpacerAtom height={10} />
      {
        items.map((it, i) => {
          return (
            <CardComponent key={`${i}_${it}`}>
              <CardRow>
                <FlexRowAtom style={{ width: '100vw' }}>
                  <div style={{
                    flex: 1,
                    paddingRight: '1em'
                  }}>
                    {isWeighable ? formatWeight(it) : `${it} uds`}
                  </div>
                  <div
                    style={{
                      color: AtlasColor.Primary00,
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      onDelete(it)
                    }}>
                    <DeleteIcon />
                  </div>
                </FlexRowAtom>
              </CardRow>
            </CardComponent>
          )
        })
      }
    </>
  )
}

export default ListOfCounts
