export enum AtlasColor {
  'Disabled' = '#686868',
  'Neutral80' = '#282828',
  'Neutral60' = '#686868',
  'Neutral40' = '#9a9a9a',
  'Neutral20' = '#cdcdcd',
  'Neutral10' = '#e6e6e6',
  'Neutral05' = '#f2f2f2',
  'Neutral00' = '#fff',
  'PrimarySurface' = '#424242',
  'Primary10Solid' = '#FEF9E7',
  'Primary10' = 'rgba(250, 206, 57, 0.12)',
  'Primary00' = '#FACE39',
  'Primary' = '#FACF39',
  'PrimaryPressed' = '#EFB806',
  'Secondary' = '#FFEEB6',
  'SecondaryPressed' = '#FFE285',
  'Background' = '#3D3D3D',
  'BackgroundTap' = '#5C5C5C',
  'BackgroundList' = '#474747',
  'BackgroundInformative1' = '#2D2D2D',
  'BackgroundInformative2' = '#252525',
  'Important' = '#FACE39',
  'StateGreen' = '#33856D',
  'BoxShadow' = '0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3);',
  'TextTitle' = '#FFFFFF',
  'TextSubtitle' = '#FFFFFF',
  'TextContent' = '333333',
  'TextStroke' = '#707070',
  'Success' = '#62E47E',
  'Info' = '#9FC6F9',
  'Error' = '#FF7070',
  'Warning' = '#FF985C',
}
