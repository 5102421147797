import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { createReader } from '../../utils/atomReader'
import { type ScannedContainer, type ScannedLocation } from '../../client/scanner/scanner.types'
import { type TransferOptionSelected } from '../../components/molecules/MoveProductsSelector.molecule'
import { type InventoryProduct } from '../../types/model'
import { ProductResponse } from 'client/inventory/inventory.types'

type ScannedLocationOrContainer = ScannedLocation | ScannedContainer

export const transferOriginInfoAtom = atomWithStorage<ScannedLocationOrContainer | null>('transferOriginInfoAtom', null)
export const transferOriginInfoReader = createReader(transferOriginInfoAtom)

export const transferDestinationInfoAtom = atomWithStorage<ScannedLocationOrContainer | null>('transferDestinationInfoAtom', null)
export const transferDestinationInfoReader = createReader(transferDestinationInfoAtom)

export const transferItemsAtom = atomWithStorage<ProductResponse[]>('transferItemsAtom', [])
export const transferItemsReader = createReader(transferItemsAtom)

export const transferSelectedItemsAtom = atomWithStorage<InventoryProduct[]>('transferSelectedItemsAtom', [])
export const transferSelectedItemsReader = createReader(transferSelectedItemsAtom)

export const transferOptionSelectedAtom = atomWithStorage<TransferOptionSelected | null>('transferOptionSelectedAtom', null)
export const transferOptionSelectedReader = createReader(transferOptionSelectedAtom)

export const transferLooseProductsAtom = atomWithStorage<ProductResponse[]>('transferLooseProductsAtom', [])
export const transferLooseProductsReader = createReader(transferLooseProductsAtom)
export const transferHasLooseProducts = atom<boolean>((get) => get(transferLooseProductsAtom).length > 0)
