import { msRestockingAxiosInstance } from 'utils/ms-restocking.axios.instance '
import { RestockInventoryCount, type AlertSuggestion, type AlertSuggestionV2, type AlertsWrapper } from './alerts.types'
import { type AlertSuggestionProduct } from 'types/model'
import { findProductsInfo, getProductDemand } from 'client/products/products.client'
import { inventoryCountingAxiosInstance } from 'utils/op-inventory-counting.axios.instance'

export async function getAlertsSuggestionsV2 (): Promise<AlertSuggestionV2[]> {
  return await msRestockingAxiosInstance()
    .get<AlertSuggestionV2[]>('/v2/suggestions')
    .then(({ data }) => data)
}


export async function getActiveProductsFromSuggestion(suggestion: string, isProductDemandOn?: boolean): Promise<AlertSuggestionProduct[]> {
  const { data } = await msRestockingAxiosInstance().get<AlertSuggestionProduct[]>(`/restocking/active/${suggestion}`);

  if (data.length > 0) {
    const products = await findProductsInfo(data.map((product) => product.productEan));

    const enrichedProducts = await Promise.all(
      data.map(async (it) => {
        const product = products.find((p) => p.ean === it.productEan);
        
        const expectedStock = isProductDemandOn ? await getProductDemand(it.productEan) : it.expectedStock;
        
        return {
          ...it,
          productImage: product?.image ?? 'https://media.justo.mx/fallback.jpg',
          isWeighable: product?.isWeighable ?? false,
          expectedStock,
        };
      })
    );

    return enrichedProducts;
  }

  return data;
}

export async function getNumberOfAlerts (): Promise<any> {
  return await msRestockingAxiosInstance()
    .get<AlertSuggestion[]>('/stats/active')
    .then(({ data }) => data)
}

interface restockProps {
  ean: string
  source: string
  target: string
  quantity: number
  description?: string
}

export const restock = async (props: restockProps) => {
  return await msRestockingAxiosInstance().post('/restock', props)
}

interface countProps {
  ean: string
  location: string
  quantity: number
  startedAt: string
}

export const restockCount = async (props: countProps): Promise<RestockInventoryCount> => {
  return await inventoryCountingAxiosInstance().post('/counting', props).then(({ data }) => data)
}