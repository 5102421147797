import { useNavigate } from 'react-router-dom'

export const useGoToUniversalSearchContainer = () => {
  const navigate = useNavigate()

  return {
    go: () => {
      navigate(UNIVERSAL_SEARCH_CONTAINER_URL)
    }
  }
}

export const UNIVERSAL_SEARCH_CONTAINER_URL = '/universal-search/container'
