import {AtlasBodyMain, AtlasBodySubtitle, AtlasColor, CardComponent, CardRow, Divider, Icon, Icons, PageContentAtom, ToolbarMolecule} from "@justomx/atlas-components";
import {useUniversalSearchContainer} from "./universal-search-container.hook";
import LoaderAtom from "../../../components/atoms/Loader.atom";
import {useTranslation} from "react-i18next";
import { ProductWithStock } from "../components/product-with-stock.component";
import { ContainerResponse, LocationResponse } from "client/scanner/scanner.types";
import { AlertSuggestionProduct } from "types/model";
import { FlexRowAtom } from "components/atoms/FlexRow.atom";
import { AlertIndicatorAtom } from "components/atoms/AlertIndicator.atom";
import EmptyListComponent from "components/molecules/EmptyList.molecule";

interface ScannedContainerProps {
  container: ContainerResponse
  location: LocationResponse
  alerts: AlertSuggestionProduct[]
}

const ScannedContainer = ({container, location, alerts}: ScannedContainerProps) => {
  const {t} = useTranslation('global')

  const urgent = alerts.filter(e => e.type === 'urgent').length
  const critical = alerts.filter(e => e.type === 'critical').length
  const warning = alerts.filter(e => e.type === 'warning').length

  return (
    <CardComponent $variant='highlight' style={{ marginBottom: '12px'}}>
      <AtlasBodySubtitle>{t('universal-search.container.scanned-container')}</AtlasBodySubtitle>
      <CardRow style={{ justifyContent: 'space-between' }}>
        <div>
        <FlexRowAtom style={{ gap: 8}}>
          <Icon src={Icons.IcPallet} size={18}/>
          <AtlasBodyMain color={AtlasColor.Neutral00}>
            {container.name}
          </AtlasBodyMain>
        </FlexRowAtom> 
        <FlexRowAtom style={{ gap: 8}}>
          <Icon src={Icons.IcUbicacionIcon} size={18}/>
          <AtlasBodySubtitle color={AtlasColor.Neutral00}>
            {location.name}
          </AtlasBodySubtitle>
        </FlexRowAtom>
        </div>
        {alerts && (
          <FlexRowAtom style={{ gap: 8 }}>
            <AlertIndicatorAtom type="urgent">
              {urgent}
            </AlertIndicatorAtom>
            <AlertIndicatorAtom type="critical">
              {critical}
            </AlertIndicatorAtom>
            <AlertIndicatorAtom type="warning">
              {warning}
            </AlertIndicatorAtom>
          </FlexRowAtom>
        )}
      </CardRow>
    </CardComponent>
  )
}


export const UniversalSearchContainerPage = () => {
  const { container, location, products, alerts, loading, goBack} = useUniversalSearchContainer()
  const {t} = useTranslation('global')

  return <>
    {loading && <LoaderAtom>{t('loader.message')}</LoaderAtom>}
    <ToolbarMolecule 
      title={t('universal-search.container.toolbar-title')}
      actionIcon={Icons.ArrowBack}
      onAction={goBack}
    />
    <PageContentAtom>
      <ScannedContainer container={container} location={location} alerts={alerts}/>
      <AtlasBodySubtitle>{t('universal-search.container.products-in-container')}</AtlasBodySubtitle>
      {products.length > 0 ?
        products.map(e => <>
          <ProductWithStock key={e.productEan+'-'+e.container} product={e}/>
          <Divider/>
          </>
        )
        :
        <EmptyListComponent text={t('universal-search.empty-list')}/>
      }
    </PageContentAtom>
  </>
}
