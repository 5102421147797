import { useNavigate } from 'react-router-dom'

export const ALERTS_REGISTER_INVENTORY_COUNT_URL = '/alerts/:source/:ean/inventory-count/:destination'

export const useAlertsRegisterInventoryCountNavigator = () => {
  const navigate = useNavigate()
  return {
    go: (source: string, ean: string, destination: string) => {
      navigate(ALERTS_REGISTER_INVENTORY_COUNT_URL
        .replace(':source', source)
        .replace(':ean', ean)
        .replace(':destination', destination))
    }
  }
}
