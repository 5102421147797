import { useNavigate } from 'react-router-dom'
import { LocationInfo } from '../../../types/model'
import { containerAdministrationDestination, containerAdministrationState } from '../ContainerAdministration.state'
import { useAtom } from 'jotai'

/**
 * Converts a Storage location to a Rack location
 *
 * @param locationName example: "EJ-ALM-P6-R2-N1-U1"
 * @return "EJ-ALM-P6-R2"
 */
const extractRackLocation = (locationName: string) => {
  return locationName.split('-').slice(0, 4).join('-')
}

export const useLowerContainerNavigator = (onError: (err: string) => void) => {
  const navigate = useNavigate()
  const [, setContainer] = useAtom(containerAdministrationState)
  const [, setDestination] = useAtom(containerAdministrationDestination)

  return {
    go: async (info: LocationInfo) => {
      try {
        if (info.locationType === 'Storage') {
          const destinationLocation = extractRackLocation(info.location)
          
          const formattedDestination: LocationInfo = {
            location: destinationLocation,
            locationType: "Storage"
          }

          setContainer(info)
          setDestination(formattedDestination)

          navigate(LOWER_CONTAINER_ROUTE)
        } else {
          onError('El contenedor no esta en posición de almacenamiento')
        }
      } catch (e: any) {
        onError(e.message as string)
      }
    }
  }
}

export const LOWER_CONTAINER_ROUTE = '/forklift/confirm-lower-to'